import React from 'react';

export const Title = () => {
  return (
    <div>
        <header>
            <h1>todo list</h1>
        </header>
    </div>
  )
}
